<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div class="ui-layout-content--full ui-form-actions" style="margin: 20px">
            <el-button
                class="newBut"
                size="mini"
                style="margin: 0; border-radius: 4px"
                type="primary"
                icon="el-icon-plus"
                @click="clickToAdd()"
                >新增</el-button
            >
        </div>
        <div class="ui-height-100" style="margin-left: 20px; margin-right: 20px; margin-top: -20px">
            <ex-search-table-pagination
                ref="searchTable"
                :fetch="fetch"
                :columns="columns"
                :formOptions="formOptions"
                :searchWhenSortChange="true"
            >
                <!-- <template #monitorType>
                    <el-form-item label="">
                        <el-cascader
                            :options="monitorSpotType"
                            collapse-tags
                            placeholder="请输入监测点类型"
                            :props="{
                                multiple: false,
                                checkStrictly: true,
                                value: 'code',
                                label: 'name',
                                children: 'children',
                            }"
                            clearable
                            v-model="monitorTypeId"
                        ></el-cascader>
                    </el-form-item>
                </template> -->
                <template slot="append">
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button type="primary" size="mini" @click="clickToAdd(scope.row, 'view')"
                                >查看</el-button
                            >
                            <el-button type="primary" size="mini" @click="clickToAdd(scope.row, 'edit')"
                                >编辑</el-button
                            >
                            <el-button type="primary" size="mini" @click="deleteInfo(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </template>
            </ex-search-table-pagination>
        </div>
        <!--新增的页面-->
        <monitor-point-edit
            ref="ref_monitorPointEdit"
            @refreshDataList="refreshDataList"
            :monitorType="monitorType"
            :monitorSpotType="monitorSpotType"
        />
    </div>
</template>
<script>
import MonitorPointEdit from './MonitorPointEdit.vue';
export default {
    components: {MonitorPointEdit},
    name: 'MintorPiont',
    data() {
        return {
            showEdit: false,
            dataList: [],
            monitorType: [], //监测类型
            monitorSpotType: [], //监测点类型
            monitorTypeId: '',
        };
    },
    computed: {
        formOptions() {
            return {
                forms: [
                    {
                        prop: 'monitorSpotTypeId',
                        itemType: 'select',
                        placeholder: '请输入监测点类型',
                        clearable: true,
                        // slotName: 'monitorType',
                        options: this.monitorSpotType,
                        labelKey: 'name',
                        valueKey: 'value',
                    },
                    {
                        prop: 'name',
                        clearable: true,
                        itemType: 'input',
                        placeholder: '请输入监测点名称',
                    },
                ],
            };
        },
        columns() {
            return [
                {prop: 'name', label: '监测点名称'},
                {
                    prop: 'monitorSpotTypeId',
                    label: '监测点类型',
                    formatter: function (row, cloums, data, index) {
                        if (row.monitorSpotTypeId) {
                            // let indexList = row.monitorTypeId.split(',');
                            // let func = this.handleTreeData(indexList.length, indexList);
                            // let obj = func(this.monitorType);
                            let obj = this.monitorSpotType.filter((item) => item.code === row.monitorSpotTypeId);
                            return obj && obj.length > 0 ? obj[0].name : '---';
                        }
                        return '---';
                    }.bind(this),
                },
                {
                    prop: 'ammeterNumber',
                    label: '电表编号',
                },
                {
                    prop: 'createUserName',
                    label: '创建账户',
                },
                {
                    prop: 'createTime',
                    label: '创建时间',
                },
            ];
        },
    },
    created() {
        let codes = ['busines_monitor_type', 'monitor_spot_type'];
        this.$client.listDictionaryChildrenTrees(codes).then((res) => {
            this.monitorSpotType = res.data.monitor_spot_type.children;
            this.monitorType = res.data.busines_monitor_type.children;
            // debugger
        });
    },
    methods: {
        closePage() {
            this.isShow = true;
            this.MonitorPointEditShow = false;
        },
        //删除信息
        deleteInfo(row) {
            this.$confirm('确认删除？', '提示', {
                confirmButtonClass: 'el-button el-button--mini el-button--primary',
                type: 'warning',
            }).then(() => {
                this.$client.powerMonitorDelete(row.id).then((res) => {
                    if (res.code === 0) {
                        this.$message({
                            message: '删除成功',
                            type: 'success',
                        });
                        this.refreshDataList();
                    } else {
                        this.$message({
                            message: '操作失败',
                            type: 'error',
                        });
                    }
                });
            });
        },
        /**
         * 刷新列表
         */
        refreshDataList() {
            this.$refs.searchTable.searchHandler();
        },
        clickToAdd(row, buttonType) {
            this.$nextTick(() => {
                let data;
                if (row?.id) {
                    this.$client.powerMonitorDetil(row.id).then((res) => {
                        data = res.data;
                        if (data) {
                            data.buttonType = buttonType;
                            this.$refs.ref_monitorPointEdit.init(data);
                        }
                    });
                } else {
                    this.$refs.ref_monitorPointEdit.init(data);
                }
            });
        },
        handleTreeData(cout, indexList) {
            let num = 0;
            return function handleData2(list) {
                //对一个list递归cout次
                if (num < cout) {
                    let parent = list.filter((item) => item.code === indexList[num]);
                    if (parent.length > 0 && indexList[indexList.length - 1] === parent[0].code) {
                        return parent;
                    }
                    num++;
                    if (parent.length > 0 && parent[0].children != null) {
                        let children = handleData2(parent[0].children);
                        return children;
                    }
                    if (parent.length === 1) return parent;
                    return list;
                }
                return list;
            };
        },
        fetch(params) {
            if (params.monitorTypeId) params.monitorTypeId = this.monitorTypeId[this.monitorTypeId.length - 1];
            // debugger;
            return this.$client.powerMonitorList(params);
        },
    },
};
</script>
<style lang="less" scoped>
.box-btn {
    // position: relative;
    // left: 92%;
    // top: 2.1em;
}
</style>
